<template>
    
        <h5 class="warning-text">TIMELINE ĐANG Ở NGÀY {{ formattedDate }}</h5>
    
</template>

<script>
export default {
    name: "DifferentDayWarning",
    computed: {
        formattedDate() {
            return this.$store.getters.selectedMomentDate.format("DD-MM-YYYY")
        }
    }
}
</script>

<style scoped>
@-webkit-keyframes my {
    0% {
        color: red;
    }

    50% {
        color: #fff;
    }

    100% {
        color: #c66262;
    }
}

@-moz-keyframes my {
    0% {
        color: red;
    }

    50% {
        color: #fff;
    }

    100% {
        color: #c66262;
    }
}

@-o-keyframes my {
    0% {
        color: red;
    }

    50% {
        color: #fff;
    }

    100% {
        color: #c66262;
    }
}

@keyframes my {
    0% {
        color: red;
    }

    50% {
        color: #fff;
    }

    100% {
        color: #c66262;
    }
}

.warning-text {
    width: 100%;
    text-align: center;
    font-size: 20px;
    background: #fff;
    color: red;
    font-weight: 600;
    -webkit-animation: my 5000ms infinite;
    -moz-animation: my 5000ms infinite;
    -o-animation: my 5000ms infinite;
    animation: my 5000ms infinite;
}
</style>
